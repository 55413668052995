import React from 'react'
import Layout from '../components/layout'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'

const blog = () => {
    const data = useStaticQuery (graphql`
    query {
        allMarkdownRemark {
            edges {
                node {
                    frontmatter {
                        title
                        tldr
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
    `)
    return (
        <Layout>
        <div className="container">
            

            <ol>
            {data.allMarkdownRemark.edges.map((edge)=>{
                return (
                    <div className="article-card">
                        <li>
                            <h2>{edge.node.frontmatter.title}</h2>
                            <p>{edge.node.frontmatter.tldr}</p>
                        </li>
                        <div className="text-center">
                        <Link to={`/${edge.node.fields.slug}`}><div className="button"role="button" id="article-button">Read More</div></Link>
                        </div>
                    </div>
                )
            })}
            </ol>
            
        </div>
        
        </Layout>
    )
}
export default blog
